import axios from "axios";

import firebaseConfig from "@/config/firebaseConfig";
import assessmentModel from "@/model/assessment/assessment";
import assessmentRoleModel from "@/model/roledisc/assessment";
// TODO: Migrate percentages to new model
import percentages from "@/model/assessment/percentages";
import roleDISCPercentages from "@/model/roledisc/percentages";
import descriptors from "@/model/assessment/descriptors";
import { ASSESSMENT_STATUS } from "@/model/assessment/constants";
import disc from "@/model/disc";
import roledisc from "@/model/roledisc";
import f1TeamQuestions from "@/model/fone-team/questions";
import f1TeamLevels from "@/model/fone-team/levels";
import f from "@/plugins/formatter";
import firebase from "@/plugins/firebase";
import { sumArray } from "@/utils/arrays";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

export default {
  create: async assessment => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error("Operación no permitida. Usuario inválido.");
    }

    const userToken = await currentUser.getIdToken();

    const newAssessment = f.objectCamelToSnakeCase(assessment, true);

    const assessmentId = await instance.post("/assessment", newAssessment, {
      headers: {
        token: userToken
      }
    });

    return assessmentId;
  },
  get: async assessmentId => {
    const { data: assessment } = await instance.get(
      `/assessment/${assessmentId}`
    );

    return assessment;
  },
  submitAssessment: async assessment => {
    const answers = assessment.answers;

    const newAssessment = f.objectCamelToSnakeCase(assessment, true);
    delete newAssessment.id;
    delete newAssessment.answers;

    switch (assessment.modelType) {
      case "DISC":
        newAssessment.profiles = parseDISCAnswers(answers);
        break;

      case "PGC":
      case "ONE_TO_ONE":
      case "F1_TEAM":
      case "SELF_LEADERSHIP":
        newAssessment.results = answers;
        break;

      case "ROLE_DISC":
        newAssessment.results = parseRoleDISCAnswers(answers);
        break;
      default:
        throw new Error(
          `Valor ${assessment.modelType} inválido para 'modelType'`
        );
    }

    try {
      await instance.put(`/assessment/${assessment.id}/submit`, newAssessment);
    } catch (error) {
      throw error;
    }
  },
  getReportData: async reportId => {
    if (reportId) {
      try {
        const { data } = await instance.get(`/assessment/report/${reportId}`);

        // TODO: Move computation logic to backend
        if (!data.modelType) {
          data.modelType = "DISC";
          data.reportType = "EXTENDED_DISC";
        }

        switch (data.modelType) {
          case "DISC":
            const processedData = percentages.get(data);
            disc.populate(processedData);
            processedData.descriptors = descriptors;
            delete processedData.receiverName;
            delete processedData.receiverEmail;
            delete processedData.accountId;
            return processedData;

          case "PGC":
            return data;
          case "ONE_TO_ONE":
            return parseOneToOneAnswers(data);
          case "F1_TEAM":
            return parseF1TeamAnswers(data);
          case "SELF_LEADERSHIP":
            return parseSelfLeadAnswers(data);
          case "ROLE_DISC":
            const processedRoleData = roleDISCPercentages.get(data);
            roledisc.populate(processedRoleData);
            processedRoleData.descriptors = descriptors;
            delete processedRoleData.receiverName;
            delete processedRoleData.receiverEmail;
            delete processedRoleData.accountId;
            return processedRoleData;
        }
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("Se requiere Id del Reporte");
    }
  },
  listAssessments: async opts => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error(
        "La lista de evaluaciones no puede ser obtenida. Usuario inválido."
      );
    }

    const userToken = await currentUser.getIdToken();

    const { data: response } = await instance.get(`/assessment/`, {
      headers: {
        token: userToken
      },
      params: { ...opts }
    });

    // TODO: Add this transformation to the backend
    return {
      count: response.count,
      data: response.data.map(d => ({
        ...d,
        statusText: ASSESSMENT_STATUS[d.status],
        sentAt: new Date(d.sentAt).toLocaleDateString("es-ES"),
        submittedAt: d.submittedAt
          ? new Date(d.submittedAt).toLocaleDateString("es-ES")
          : "-"
      }))
    };
  },
  resendAssessment: async (assessment, assessmentId) => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error("Operación no permitida. Usuario inválido.");
    }

    const userToken = await currentUser.getIdToken();

    // TODO: Decide on keep object transformation in the backend or just change the format
    const newAssessment = f.objectCamelToSnakeCase(assessment, false);

    await instance.put(`/assessment/${assessmentId}/resend`, newAssessment, {
      headers: {
        token: userToken
      }
    });
  },
  deleteAssessment: async assessmentId => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error("Operación no permitida. Usuario inválido.");
    }

    const userToken = await currentUser.getIdToken();

    await instance.delete(`/assessment/${assessmentId}`, {
      headers: {
        token: userToken
      }
    });
  },
  closeAssessment: async assessmentId => {
    const currentUser = firebase.auth().currentUser;

    if (!currentUser) {
      throw new Error("Operación no permitida. Usuario inválido.");
    }

    const userToken = await currentUser.getIdToken();

    try {
      await instance.put(`/assessment/${assessmentId}/close`, undefined, {
        headers: {
          token: userToken
        }
      });
    } catch (error) {
      throw error;
    }
  }
};

function parseDISCAnswers(answers) {
  const consciousProfileId = [];
  const primaryProfileId = [];
  const consciousCounters = { D: 0, I: 0, S: 0, C: 0 };
  const primaryCounters = { D: 0, I: 0, S: 0, C: 0 };

  assessmentModel.questions.forEach((question, i) => {
    const answerThatMost = answers[i]["up"].index;
    const answerThatLess = answers[i]["dw"].index;

    const attributeThatMost = question.attributes[answerThatMost];
    const attributeThatLess = question.attributes[answerThatLess];

    const mt = attributeThatMost.value;
    const lt = attributeThatLess.value;

    consciousProfileId.push(mt);
    primaryProfileId.push(lt);

    if (hasToCount(mt, i)) {
      consciousCounters[mt]++;
    }
    if (hasToCount(lt, i)) {
      primaryCounters[lt]++;
    }
  });

  return {
    primary: {
      factorsPerAnswer: primaryProfileId.join(""),
      values: primaryCounters
    },
    conscious: {
      factorsPerAnswer: consciousProfileId.join(""),
      values: consciousCounters
    }
  };
}
function parseRoleDISCAnswers(answers) {
  const profileId = [];
  const counters = { D: 0, I: 0, S: 0, C: 0 };

  assessmentRoleModel.questions.forEach((question, i) => {
    const answer = answers[i].index;

    const attribute = question.attributes[answer];

    const value = attribute.value;

    profileId.push(value);

    if (hasToCount(value, i)) {
      counters[value]++;
    }
  });

  return {
    factorsPerAnswer: profileId.join(""),
    values: counters
  };
}
function hasToCount(factor, questionIndex) {
  if (factor === "D" && [2, 17, 27].includes(questionIndex)) {
    // Count 0 for questions 3, 18, 28
    return false;
  } else if (factor === "C" && [13, 16].includes(questionIndex)) {
    // Count 0 for questions 14, 17
    return false;
  }
  return true;
}

function parsePGCAnswers(answers) {
  const result = {};

  answers.forEach(answer => {
    result[answer] = (result[answer] || 0) + 1;
  });

  return result;
}

function parseOneToOneAnswers(data) {
  const { applicants, results } = data;
  const { assessed, ...rest } = results;

  const job = sumArray([...assessed.job]);
  const motivation = sumArray([...assessed.motivation]);
  const relationships = sumArray([...assessed.relationships]);

  const answerCounts = {
    job: new Array(7).fill(undefined).map(() => ({})),
    motivation: new Array(7).fill(undefined).map(() => ({})),
    relationships: new Array(7).fill(undefined).map(() => ({})),
    aspects: {}
  };

  for (const applicantId in rest) {
    for (const category in rest[applicantId]) {
      for (const questionIndex in rest[applicantId][category]) {
        const value = rest[applicantId][category][questionIndex];

        if (category === "aspects") {
          if (!answerCounts[category][value]) {
            answerCounts[category][value] = 0;
          }
          answerCounts[category][value]++;
        } else {
          if (!answerCounts[category][questionIndex][value]) {
            answerCounts[category][questionIndex][value] = 0;
          }
          answerCounts[category][questionIndex][value]++;
        }
      }
    }
  }

  return {
    ...data,
    results: {
      assessedAnswers: assessed,
      assessedCounts: {
        job,
        motivation,
        relationships
      },
      answerCounts,
      applicantsCount: applicants ? applicants.length : 0
    }
  };
}

function parseF1TeamAnswers(data) {
  const { assessed, results } = data;
  const { applicant, ...rest } = results;

  // Sums values of all the answers of the applicant
  const applicantCount = f1TeamQuestions.reduce(
    (prev, curr) => prev + applicant[curr.key],
    0
  );

  // Prepares an object to store the counts by factor
  const assessedCountByFactor = f1TeamQuestions.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.key]: {}
    }),
    {}
  );

  // Sums the chosen answer by factor
  for (const assessedId in rest) {
    for (const factor in assessedCountByFactor) {
      if (rest[assessedId][factor] !== undefined) {
        const answer = rest[assessedId][factor];

        if (assessedCountByFactor[factor][answer] === undefined) {
          assessedCountByFactor[factor][answer] = 0;
        }
        assessedCountByFactor[factor][answer] += 1;
      }
    }
  }

  // Prepares an object to store the sum of values by assessed
  const countByAssessed = {};

  // Sums the values by assessed
  for (const assessedId in rest) {
    for (const factor in assessedCountByFactor) {
      if (rest[assessedId][factor] !== undefined) {
        const answer = rest[assessedId][factor];

        if (countByAssessed[assessedId] === undefined) {
          countByAssessed[assessedId] = 0;
        }
        countByAssessed[assessedId] += answer;
      }
    }
  }

  // Prepares an object to store the count of assessed by level
  const assessedByLevel = f1TeamLevels.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.level]: 0
    }),
    {}
  );

  // Counts the assessed by level
  Object.values(countByAssessed).forEach(count => {
    const level = f1TeamLevels.find(level => count <= level.range.max);
    assessedByLevel[level.level]++;
  });

  return {
    ...data,
    results: {
      applicantAnswers: applicant,
      applicantCount,
      assessedCountByFactor,
      assessedByLevel
    }
  };
}

function parseSelfLeadAnswers(data) {
  const { results } = data;
  const { emotion, mind, behavior } = results;

  const emotionsum = sumArray(emotion);
  const mindsum = sumArray(mind);
  const behaviorsum = sumArray(behavior);

  const totalSum = emotionsum + mindsum + behaviorsum;

  return {
    ...data,
    results: {
      applicantCount: {
        emotionsum,
        mindsum,
        behaviorsum
      },
      answerCounts: totalSum,
      emotion,
      mind,
      behavior
    }
  };
}

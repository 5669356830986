import axios from "axios";
import firebaseConfig from "@/config/firebaseConfig";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

export default {
  getUserInvite: inviteId => {
    return instance.get(`/user/invite/${inviteId}`);
  }
};

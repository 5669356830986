export const USER_RECORD_TYPE = {
  USER: "user",
  INVITE: "invite"
};

export const USER_RECORD_STATUS_LABEL = {
  ACTIVE: "Activo",
  INACTIVE: "Inactivo",
  SENT: "Enviado"
};

import assessmentService from "@/services/assessmentService";

export default {
  state: () => ({
    currentStep: 0,
    assessment: null,
    answers: null,
    applicantId: null,
    assessedId: null,
    processingRequest: false
  }),
  mutations: {
    back(state) {
      if (state.currentStep > 0) {
        state.currentStep--;
      }
    },
    // TODO: Fix structure
    clear(state) {
      state.currentStep = 0;
      state.assessment = null;
      state.applicantId = null;
      state.assessedId = null;
    },
    next(state) {
      state.currentStep++;
    },
    setAnswers(state, answers) {
      state.answers = answers;
    },
    setAssessment(state, assessment) {
      state.assessment = assessment;
    },
    setApplicantId(state, applicantId) {
      state.applicantId = applicantId;
    },
    setAssessedId(state, assessedId) {
      state.assessedId = assessedId;
    },
    /**
     * Sets the processing request state flag.
     * @param {Object} state Store state object.
     * @param {boolean} processingRequest Processing request flag value.
     */
    setProcessingRequest(state, processingRequest) {
      state.processingRequest = processingRequest;
    }
  },
  actions: {
    clearAssessment({ commit }) {
      commit("clear");
    },
    createAssessment({ commit }, assessment) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        assessmentService
          .create(assessment)
          .then(assessmentId => {
            commit("setProcessingRequest", false);
            resolve(assessmentId);
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    async getAssessment({ commit }, assessmentId) {
      try {
        commit("setProcessingRequest", true);
        const assessment = await assessmentService.get(assessmentId);
        commit("setAssessment", assessment);
        return assessment;
      } catch (error) {
        throw error;
      } finally {
        commit("setProcessingRequest", false);
      }
    },
    back({ commit }) {
      commit("back");
    },
    next({ commit }) {
      commit("next");
    },
    setAnswers({ commit }, answers) {
      commit("setAnswers", answers);
    },
    setApplicantId({ commit }, applicantId) {
      commit("setApplicantId", applicantId);
    },
    setAssessedId({ commit }, assessedId) {
      commit("setAssessedId", assessedId);
    },
    setAssessment({ commit }, assessment) {
      return new Promise(resolve => {
        commit("setAssessment", assessment);
        resolve();
      });
    },
    submitAssessment({ commit }) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        const assessment = this.getters.assessment;
        assessment.answers = this.getters.answers;
        assessmentService
          .submitAssessment(assessment)
          .then(() => {
            commit("clear");
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            console.log(error);
            commit("setProcessingRequest", false);
            reject(new Error("Ha ocurrido un error inesperado"));
          });
      });
    },
    getReportData({ commit }, reportId) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        assessmentService
          .getReportData(reportId)
          .then(data => {
            commit("setProcessingRequest", false);
            resolve(data);
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    resendAssessment({ commit }, payload) {
      commit("setProcessingRequest", true);
      const { assessment, assessmentId } = payload;
      return new Promise((resolve, reject) => {
        assessmentService
          .resendAssessment(assessment, assessmentId)
          .then(() => {
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    deleteAssessment({ commit }, assessmentId) {
      commit("setProcessingRequest", true);

      return new Promise((resolve, reject) => {
        assessmentService
          .deleteAssessment(assessmentId)
          .then(() => {
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    closeAssessment({ commit }, assessmentId) {
      commit("setProcessingRequest", true);

      return new Promise((resolve, reject) => {
        assessmentService
          .closeAssessment(assessmentId)
          .then(() => {
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    }
  },
  getters: {
    answers(state) {
      return state.answers;
    },
    currentStep(state) {
      return state.currentStep;
    },
    assessment(state) {
      return state.assessment;
    },
    applicantId(state) {
      return state.applicantId;
    },
    assessedId(state) {
      return state.assessedId;
    },
    processingRequest(state) {
      return state.processingRequest;
    }
  }
};

import userService from "@/services/userService";

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    listUsers() {
      return userService.listUsers();
    },
    async sendInvitation({ commit }, { email, displayName }) {
      commit("setProcessingRequest", true);

      try {
        await userService.inviteUser({ email, displayName });
      } catch (error) {
        console.error("Error sending user invite:", error);
        throw error;
      } finally {
        commit("setProcessingRequest", false);
      }
    }
  },
  getters: {}
};

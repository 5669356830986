<template>
  <v-row class="text-right justify-space-between align-center">
    <v-col cols="12" md="6">
      <o-text-field
        label="Filtrar por Aplicante / Receptor"
        hide-details
        v-model="searchText"
        :disabled="disabled"
        @input="applyFilter"
      />
    </v-col>

    <v-col cols="6" md="3">
      <o-select
        itemText="text"
        itemValue="id"
        label="Tipo"
        uid="type"
        v-model="type"
        :clearable="false"
        :data="typeList"
        :disabled="disabled"
        :hide-details="true"
        @change="applyFilter"
      />
    </v-col>

    <v-col cols="6" md="3">
      <o-select
        itemText="text"
        itemValue="id"
        label="Estado"
        uid="status"
        v-model="status"
        :clearable="false"
        :data="statusList"
        :disabled="disabled"
        :hide-details="true"
        @change="applyFilter"
      />
    </v-col>
  </v-row>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { debounce } from "debounce";

import { ASSESSMENT_STATUS, MODEL_TYPES } from "@/model/assessment/constants";

export default defineComponent({
  name: "DashBoardFilter",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    initialValue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      searchText: "",
      status: "SENT",
      type: "ALL"
    };
  },
  methods: {
    applyFilter: debounce(function () {
      const { searchText, status, type } = this;

      this.$emit("filter", {
        searchText,
        status,
        type
      });
    }, 500),
  },
  computed: {
    statusList() {
      const status = Object.entries(ASSESSMENT_STATUS).map(([id, text]) => ({
        id,
        text
      }));

      return [
        ...status
      ];
    },
    typeList() {
      const type = Object.entries(MODEL_TYPES).map(([id, text]) => ({
        id,
        text
      }));

      return [
        { id: "ALL", text: "Todos" },
        ...type
      ];
    }
  },
  created() {
    this.searchText = this.initialValue.searchText;
    this.status = this.initialValue.status;
    this.type = this.initialValue.type;
  }
});
</script>

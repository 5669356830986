<template>
  <div id="" class="section-background">
    <report-section-title
      >Diagnóstico de Madurez del Equipo</report-section-title
    >

    <v-container fluid>
      <v-row no-gutters>
        <v-col no-gutters cols="0" md="1" />
        <v-col no-gutters cols="12" md="10" class="px-sm-1 pb-4">
          <h2>Introducción</h2>

          <p class="intro-text">
            En <strong>LíderLab</strong>, más que ofrecer evaluaciones
            estáticas, nos enfocamos en generar
            <strong>transformación real y resultados medibles.</strong> Nuestro
            <strong>Test de Madurez de Equipo</strong> está diseñado para
            diagnosticar el estado actual del equipo y brindar estrategias
            concretas para su evolución.
          </p>

          <br />
          <h3 class="h3-ft">Fundamento teórico</h3>

          <p class="intro-text">
            Este modelo está adaptado de la propuesta del
            <strong>Dr. Bruce Tuckman</strong>, quien identificó cuatro etapas
            clave en la evolución de un equipo:
          </p>

          <ol class="intro-list">
            <li>
              <strong>Formación</strong>: Los integrantes comienzan a conocerse,
              las expectativas son altas, pero aún no hay una dinámica clara de
              trabajo.
            </li>
            <li>
              <strong>Conflicto:</strong> Surgen conflictos y diferencias, ya
              que los miembros ajustan sus estilos y roles dentro del equipo.
            </li>
            <li>
              <strong>Normalización:</strong> Se establecen reglas y normas de
              convivencia, la colaboración mejora y se construye confianza.
            </li>
            <li>
              <strong>Desempeño:</strong> El equipo trabaja con autonomía y
              eficacia, enfocado en los objetivos y con alta productividad.
            </li>
          </ol>

          <p class="intro-text">
            El Dr. Bruce Tuckman no definió explícitamente el concepto de
            <strong>equipo de alto desempeño</strong>, pero su modelo de
            desarrollo de equipos sugiere que un equipo alcanza su máximo
            rendimiento en la
            <strong>etapa de "Performing" (Desempeño).</strong>
          </p>

          <p class="intro-text">En esta fase, el equipo:</p>

          <ul class="intro-list">
            <li>Funciona con <strong>autonomía y cohesión.</strong></li>
            <li>
              Tiene una <strong>visión clara</strong> y está alineado con los
              objetivos.
            </li>
            <li>
              Opera con <strong>confianza mutua</strong>, comunicación efectiva
              y mínima supervisión.
            </li>
            <li>
              Es altamente <strong>productivo y adaptable</strong> ante
              desafíos.
            </li>
            <li>
              Cada miembro <strong>asume responsabilidad</strong> y contribuye
              activamente al éxito colectivo.
            </li>
          </ul>

          <p class="intro-text">
            Por lo tanto, en el enfoque de Tuckman, un
            <strong>equipo de alto desempeño</strong>
            es aquel que ha superado las etapas iniciales de formación,
            conflicto y normalización, logrando un nivel de eficiencia y
            colaboración que le permite operar con fluidez y obtener resultados
            excepcionales.
          </p>

          <p class="intro-text">
            Nuestro modelo de madurez de equipo toma estos principios y los
            adapta a la realidad empresarial actual, integrando herramientas
            prácticas para acelerar el desarrollo del equipo y garantizar su
            efectividad.
          </p>

          <p class="intro-text">
            A continuación, se presentan los resultados de la evaluación junto
            con recomendaciones para fortalecer el desempeño y la cohesión del
            equipo.
          </p>

          <h2 class="h2-text">Diagnóstico de Madurez del Equipo</h2>

          <p class="intro-text"><strong>Objetivo del diagnóstico</strong></p>

          <p class="intro-text">
            Este informe proporciona al líder una guía práctica y efectiva para
            impulsar el rendimiento del equipo a través de estrategias de alto
            impacto.
          </p>

          <p class="intro-text">
            Los resultados y recomendaciones presentados a continuación se basan
            en el análisis de la información recopilada mediante el instrumento
            aplicado por el líder, ofreciendo un enfoque claro y accionable para
            la evolución del equipo.
          </p>

          <h2>
            Características Claves de un Equipo de Alto Desempeño Analizadas en
            Este Estudio
          </h2>
        </v-col>
        <v-col cols="0" md="1" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({ name: "SectionReadyToKnow" });
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.section-background {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: var(--v-primary-base);
  justify-content: center;
}
.intro-text {
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: justify;
}
.h2-text {
  font-size: 26px;
}
.h3-ft {
  text-align: left;
}
.intro-list {
  text-align: left;
  padding-left: 2.5em;
  margin-top: -0.5em;
}
.intro-list li {
  margin-bottom: 0.5em;
}
.four-factors {
  background: linear-gradient(
    45deg,
    var(--v-primary-base) 0%,
    var(--v-primary-lighten2) 100%
  );
  padding-bottom: 1em;
}
@media print {
  .intro-text {
    margin-top: -1em;
  }
  .four-factors {
    padding-bottom: 1.3em;
  }
  .col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>

<template>
  <div id="" class="section-background">
    <div class="acrostic">
      <p class="bold-text">
        <span class="capital">F</span>acultados para la autonomía y la
        innovación
      </p>
      <p class="bold-text">
        <span class="capital">O</span>rganizados en función de una visión
        compartida
      </p>
      <p class="bold-text">
        <span class="capital">R</span>elacionados con buena voluntad
      </p>
      <p class="bold-text"><span class="capital">M</span>otivados</p>
      <p class="bold-text">
        <span class="capital">U</span>san sus recursos y competencias
        eficientemente
      </p>
      <p class="bold-text"><span class="capital">L</span>ogran la excelencia</p>
      <p class="bold-text">
        <span class="capital">A</span>mplían la conciencia compartida
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FOneTeam"
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.section-background {
  display: block;
  margin-left: 9%;
  background-color: #fff;
  color: var(--v-primary-base);
}
.acrostic {
  justify-content: center;
  font-weight: 400;
  color: var(--v-primary-base);
  text-align: left;
  font-size: 1rem;
  margin: 0 auto;
}
.capital {
  font-weight: 600;
  color: var(--v-primary-base);
  font-size: 1.8rem;
}
.bold-text {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2px;
}
</style>

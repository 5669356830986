import userInviteService from "@/services/userInviteService";

export default {
  state: () => ({
    userInvite: null
  }),
  mutations: {
    setUserInvite(state, data) {
      state.userInvite = data;
    }
  },
  actions: {
    async fetchUserInvite({ commit }, inviteId) {
      try {
        const response = await userInviteService.getUserInvite(inviteId);
        commit("setUserInvite", response.data);
        return response.data;
      } catch (error) {
        console.error("Error fetching user invite:", error);
        throw error;
      }
    }
  },
  getters: {
    userInvite(state) {
      return state.userInvite;
    }
  }
};
